/**
 * ECCUBE 固有のスタイルユーティリティ
 */

@mixin borderTop(){
  border-top: 1px dotted #ccc;
}

@mixin borderBottom(){
  border-bottom: 1px dotted #ccc;
}

@mixin reset_link(){
  a{
    color: inherit;
    text-decoration: none;
  }
  a:hover{
    text-decoration: none;
  }
}

@mixin form-control-focus($color: $input-border-focus) {
  $color-rgba: rgba(red($color), green($color), blue($color), .6);
  &:focus {
    border-color: $color;
    outline: 0;
    @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $color-rgba);
  }
}

@mixin placeholder($color: $input-color-placeholder) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }
  &:-ms-input-placeholder { color: $color; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

@mixin tab-focus() {
  // WebKit-specific. Other browsers will keep their default outline style.
  // (Initially tried to also force default via `outline: initial`,
  // but that seems to erroneously remove the outline in Firefox altogether.)
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

@mixin opacity($opacity) {
  $opacity-ie: ($opacity * 100);  // IE8 filter
  filter: alpha(opacity=$opacity-ie);
  opacity: $opacity;
}
