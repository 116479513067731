@import "../mixins/media";
/*
会員情報編集完了

会員情報編集完了で使用するページコンポーネントです。

ex [会員情報編集完了](http://demo3.ec-cube.net/mypage/change_complete)

Markup:
include /assets/tmpl/elements/22.1.editComplete.pug
+ec-userEditCompleteRole

Styleguide 22.1

*/
.ec-userEditCompleteRole{
  @include container;
  text-align: center;
  padding: 0 16px;
  & &__title{
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 24px;
    @include media_desktop(){
      font-size: 32px;
    }
  }
  & &__description{
    margin-bottom: 32px;
    font-size: 16px;
  }
}
